import {graphql} from 'gatsby'
import React from 'react'

import {Layout, MetaData} from '../../../components'
import {
  ThemePageHeader,
  ThemeFeatureCard,
  FeatureCards,
  Features,
  FeaturesList,
} from '../../../components/ThemePage'
import CTA, {CTAItem} from '../../../components/CTA'

import MembershipIcon from '../../../images/inline-svgs/online-payment.svg'
import GhostIcon from '../../../images/inline-svgs/ghost.svg'
import DocumentationIcon from '../../../images/inline-svgs/documents.svg'
import DownloadIcon from '../../../images/inline-svgs/download.svg'
import ResponsiveIcon from '../../../images/inline-svgs/responsive.svg'

const SublimeGhostTheme = ({data}) => (
  <Layout>
    <MetaData
      title="Sublime – A Ghost Blogging Theme"
      description="A clean, minimal, and elegant ghost blogging theme ideal for creating magazines &amp; portfolios. Anything from design, architecture and photography work would look great in this theme."
    />
    <ThemePageHeader
      title="Sublime"
      eyebrow="Ghost"
      description="A clean, minimal, and elegant <a href='https://ghost.org' class='link--hover--underline' target='_blank' rel='noopener noreferrer'>Ghost</a> blogging theme ideal for creating magazines &amp; portfolios. Anything from design, architecture and photography work would look great in this theme."
      price="$ 30"
      purchaseLink="https://gumroad.com/l/sublime"
      demoLink="https://sublime.stylesheets.dev/"
      image={data.header.childImageSharp.gatsbyImageData}
      eyebrowLink="/themes/ghost/"
    />

    <FeatureCards>
      <ThemeFeatureCard
        title="Ghost 4.x"
        description="Supports latest version of Ghost."
      >
        <GhostIcon fill="#444" width="40px" />
      </ThemeFeatureCard>
      <ThemeFeatureCard
        title="Memberships Support"
        description="Add Ghost memberships and subscription feature to your site."
      >
        <MembershipIcon fill="#444" />
      </ThemeFeatureCard>
      <ThemeFeatureCard
        title="Fully Documented"
        description="Fully documented for setting up and making customizations on your site."
      >
        <DocumentationIcon fill="#444" />
      </ThemeFeatureCard>
    </FeatureCards>

    <Features>
      <FeaturesList>
        <li>Support latest versions of Ghost 4.0+</li>
        <li>Supports all basic features of Ghost</li>
        <li>Fully customisable typography and color palette</li>
        <li>Infinite loading layout with load more button</li>
        <li>Ghost premium membership feature support</li>
        <li>Post sharing icons for Twitter, Facebook, Pinterest etc.</li>
        <li>Space for search integration in site navbar</li>
        <li>
          All major social media icons included in the theme (Facebook, Twitter,
          Instagram, Github, Pinterest, Linkedin, Youtube, Tumblr, Medium,
          Reddit, 500px, Dribbble)
        </li>
        <li>Space for integrating comments from services like Disqus etc.</li>
        <li>Free and regular updates</li>
        <li>Google fonts</li>
      </FeaturesList>
    </Features>

    <CTA title="Ready to Get Started?">
      <CTAItem
        title="Purchase Theme"
        link="https://gumroad.com/l/sublime"
        externalLink
      >
        <DownloadIcon fill="#fff" />
      </CTAItem>
      <CTAItem
        title="View Demo"
        link="https://sublime.stylesheets.dev/"
        externalLink
      >
        <ResponsiveIcon fill="#fff" />
      </CTAItem>
      <CTAItem
        title="Documentation"
        link="https://docs.stylesheets.dev/sublime/"
        externalLink
      >
        <DocumentationIcon fill="#fff" />
      </CTAItem>
      {/* <CTAItem title="FAQs" link="/faq/">
        <FAQIcon fill="#fff" />
      </CTAItem> */}
    </CTA>
  </Layout>
)

export default SublimeGhostTheme

export const query = graphql`
  query {
    header: file(relativePath: {eq: "themes/sublime.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
